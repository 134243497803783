import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='Iterative Improvement. Or Not.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button as={GLink} to='/contact' sx={styles.button}>
              Contact Me
            </Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
            <Section title='My Story'>
                <Text variant='p'>
                  I grew up near Kansas City, in a rural area that's now pure suburbia. 
                  My first job was selling newspaper subscriptions door to door for the Olathe Daily News. 
                  In highschool, I went to work for Johnson County, Kansas government, and was ultimately
                  responsible for land records software.
                </Text>
                <Text variant='p'>
                  Land Records software took me into Public Key Infrastructure (PKI), and to the Baltimore-Washington region.
                  I founded a hosting company that morphed into an early VoIP carrier, named a top service provider in 2005, 
                  along with companies such as Level3 and MCI. This lead to the acquisition of a CLEC, and eventually connected me 
                  to the Shoutpoint team. Shoutpoint is a large scale telephony applications developer and carrier.
                </Text>
                <Text variant='p'>
                  The minds and technical stack behind Shoutpoint ultimately built the Voxology CPaaS. Voxology, Shoutpoint, and Voxology
                  Carrier Services now form Voxology Group.
                </Text>
                <Text variant='p'>
                  The Voxology team has built a deliberate culture around its team of highly skilled employees.
                </Text>
                <Text variant='p'>
                  These days, I live in Chicago with my wife, son, and cats.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Telecom'>
                <Card variant='paper'>
                  Telecom (primarily VoIP) has a big part of my life for much of the last 20 years. Give me another 20, and perhaps I'll be an expert.
                </Card>
              </Section>
              <Divider />
              <Section title='Technology'>
                <Card variant='paper'>
                  In recent years, I'm hands-on less than 25%. :-( But I do spend some time in go, perl, and puppet. (And I deep-dive into SIP from time to time).
                </Card>
              </Section>
              <Divider />
              <Section title='Operations'>
                <Card variant='paper'>
                  I'm currently responsible for administrative and technical operations of the Voxology Group, where I serve as President of Voxology Carrier Services.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query shadowAboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
